html, body {
  margin: 0;
  font-family: "Wix Madefor Text", sans-serif !important;
  color: #fff !important;
  background-color: #0e1015 !important;
  overflow-x: hidden !important;
}

.blurred-background {
  background: linear-gradient(145deg, rgba(255,255,255,0.1), rgba(0,0,0,0.5));
  backdrop-filter: blur(5px);
}

/* Ensure nav links are right-aligned on larger screens */


.nav-link {
  margin: 0px 20px;
  font-weight: 600;
  color: white !important;
}

.navbar {
  padding: 10px 30px;
  border-radius: 90px;
  font-weight: bold;
  border: 2px solid #615286 !important;
  background-color: transparent;
  background: linear-gradient(to right, #7754c8, rgba(0,0,0,0), rgba(0,0,0,0.1));
  backdrop-filter: blur(10px);
}

h1 {
  font-weight: 400 !important;
  font-size: 50px !important;
}

p {
  color: #c3c3c3;
}

.cta-button {
  border-radius: 90px !important;
}

.cta-button-light {
  background-color: #a37eea !important;
  border: 1px solid transparent !important;
  color: #0e1015 !important;
}

.cta-button-dark {
  background-color: transparent !important;
  color: #a37eea;
  border: 1px solid #a37eea !important;
  background: linear-gradient(to right, #202430, #1a1e28, #14161d, #14161d, #101218) !important;
}

.custom-container {
  position: relative;
  font-family: 'Arial', sans-serif;
}

.title-container {
  position: relative;
  border-radius: 45px;
  padding: 20px;
  background: linear-gradient(to right, #b693f4, #b693f4, #b693f4, #b693f4, #fff) !important;
}

.title {
  color: black;
  margin: 0;
}

.subtitle {
  position: absolute;
  bottom: -25px; /* Adjust based on your design */
  left: 0px; /* Adjust based on your design */
  background: linear-gradient(to right, #0e1015, #101115, #232325, #313134, #2c2c2d) !important;
  color: white;
  padding: 5px 15px;
  border-radius: 45px;
  margin: 0;
  border: 1px solid #a37eea !important;
}

.description {
  color: #c3c3c3; /* Your chosen color for the text */
  margin-top: 40px; /* Adjust the space below the title */
}

.beyond-box {
  background: linear-gradient(to right, #0e1015, #212226, #232325, #21222a, #222431) !important;
  border-radius: 90px;
  height: 150px;
  border-bottom: 1px solid #a37eea !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
}

.steps-description {
  font-size: 24px;
  max-width: 700px;
}

.steps-description-purple {
  font-size: 24px;
  max-width: 700px;
}

.bullet-title {
  font-size: 40px !important;
  max-width: 300px !important;
}

.bullet-description {
  font-size: 22px !important;
  color: #c3c3c3 !important;
  max-width: 400px !important;
}

.pricing-box {
  background: linear-gradient(to right, #0e1015, #212226, #232325, #21222a, #222431) !important;
  border-radius: 90px;
  height: 100px;
  border-bottom: 1px solid #a37eea !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
}

.pricing-card-1 {
  position: relative;
  background: linear-gradient(to right, #222431, #181c24, #15171c, #101418, #101018) !important;
  border-radius: 65px !important;
  overflow: hidden;
}

.pricing-card-1::before,
.pricing-card-1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  z-index: 1;
}

.pricing-card-1::before {
  top: 0;
  background: linear-gradient(to right, 
    rgba(163, 126, 234, 0),
    #a37eea,
    rgba(163, 126, 234, 0));
}

.pricing-card-1::after {
  bottom: 0;
  background: linear-gradient(to right, 
    rgba(163, 126, 234, 0),
    #a37eea,
    rgba(163, 126, 234, 0));
}


.pricing-title-1 {
  font-size: 46px !important;
  padding: 30px 40px;
  color: #d3c7ef !important;
}

.pricing-price-1 {
  color: #d9cdf5 !important;
  font-size: 20px !important;
}

.pricing-staking-1 {
  color: #59595d !important;
  text-decoration-line: line-through !important; 
}










.pricing-card-2 {
  position: relative;
  background: linear-gradient(to right, #222431, #181c24, #15171c, #101418, #101018) !important;
  border-radius: 65px !important;
  overflow: hidden;
}

.pricing-card-2::before,
.pricing-card-2::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  z-index: 1;
}

.pricing-card-2::before {
  top: 0;
  background: linear-gradient(to right, 
    rgba(163, 126, 234, 0),
    #a37eea,
    rgba(163, 126, 234, 0));
}

.pricing-card-2::after {
  bottom: 0;
  background: linear-gradient(to right, 
    rgba(163, 126, 234, 0),
    #a37eea,
    rgba(163, 126, 234, 0));
}


.pricing-title-2 {
  font-size: 46px !important;
  padding: 30px 40px;
  color: #b696f6 !important;
}

.pricing-price-2 {
  color: #d9cdf5 !important;
  font-size: 20px !important;
}

.pricing-staking-2 {
  color: #d9cdf5 !important;
}








.pricing-card-3 {
  position: relative;
  background: linear-gradient(to right, #825bd5, #8a62db, #9671e3, #a682eb, #b18ef3) !important;
  border-radius: 65px !important;
  overflow: hidden;
}

.pricing-card-3::before,
.pricing-card-3::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  z-index: 1;
}

.pricing-card-3::before {
  top: 0;
  background: linear-gradient(to right, 
    rgba(163, 126, 234, 0),
    #a37eea,
    rgba(163, 126, 234, 0));
}

.pricing-card-3::after {
  bottom: 0;
  background: linear-gradient(to right, 
    rgba(163, 126, 234, 0),
    #a37eea,
    rgba(163, 126, 234, 0));
}


.pricing-title-3 {
  font-size: 46px !important;
  padding: 30px 40px;
  color: #0e1015 !important;
}

.pricing-price-3 {
  color: #0e1015 !important;
  font-size: 20px !important;
}

.pricing-staking-3 {
  color: #0e1015 !important;
}

.staking-description {
  font-size: 22px;
  color: #d9cdf5;
  max-width: 800px;
}

.navbar-toggler {
  border: 1px solid transparent !important;
}

@media only screen and (max-width: 760px) {

  .steps-description {
    font-size: 18px;
  }

  .steps-description-purple {
    font-size: 18px;
  }

  .beyond-box {
    font-size: 16px;
    padding: 0px 20px;
  }

  .steps-description, .steps-description-purple {
    margin-top: 20px;
  }

  .beyond-box {
    height: 100px;
  }

  h1 {
    font-size: 38px !important;
  }

  .navbar {
    padding: 0px 0px !important;
    margin: 10px 10px !important;
    border-radius: 25px !important;
  }

}


.title-container-roadmap {
  position: relative;
  border-radius: 45px;
  padding: 20px;
  background: linear-gradient(to right, #202430, #1a1e28, #14161d, #14161d, #101218) !important;
  border-left: 1px solid #835cd8;
}

.title-roadmap {
  color: #835cd8;
  margin: 0;
}

.subtitle-roadmap {
  color: #c1c1c1;
}

.roadmap-card {
  background-color: #202430 !important;
}

.tokenomics-title {
  color: #835cd8 !important;
}

.custom-container-roadmap{
  min-width: 320px;
}

.footer-link {
  cursor: pointer;
}